<template>
  <b-card-code
    no-body
    title="Registry Number List"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">

        <div class="col-3">
          <label>Filter by User</label>
          <v-select
            v-model="filter.user_id"
            :options="users"
            :reduce="option => option.id"
            @search="onSearchUser"
          >
            <template slot="no-options">
              Type user name, code, or email..
            </template>
          </v-select>
        </div>

        <div class="col-3">
          <label>Filter by Status</label>
          <select
            v-model="filter.status"
            class="form-control form-control-sm"
          >
            <option value="">
              -- All Status --
            </option>
            <option value="pending">
              Pending
            </option>
            <option value="approved">
              Approved
            </option>
            <option value="rejected">
              Rejected
            </option>
          </select>
        </div>

      </div>
    </div>
    <br>

    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>User</th>
            <th>Registry</th>
            <th>Registry Number</th>
            <th>Creation Time</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.id"
          >
            <td v-if="item.user != null">
              <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                {{ item.user.name }}
              </router-link>
            </td><td v-else>
              -
            </td>
            <td>{{ item.registry.name }}</td>
            <td>{{ item.registry_number }}</td>
            <td>{{ item.created_at }}</td>
            <td style="text-transform: capitalize;">
              {{ item.status }}
            </td>
            <td>
              <div
                class="btn-group"
                role="group"
              >
                <button
                  v-if="checkPermission('update registry number')"
                  class="btn btn-warning btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                  @click="editItem(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </button>
                <button
                  v-if="item.status != 'approved' && checkPermission('approve registry number')"
                  class="btn btn-success btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Approve"
                  @click="approve(item.uuid)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </button>
                <button
                  v-if="item.status != 'rejected' && checkPermission('reject registry number')"
                  class="btn btn-danger btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Reject"
                  @click="reject(item.uuid)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                  />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="form-modal"
      no-close-on-backdrop
      :title="editUuid != null ? 'Edit Registry' : 'Add Registry'"
    >
      <div class="form">

        <div class="form-group">
          <label for="">Registry Number:</label>
          <input type="text" class="form-control" v-model="formPayload.registry_number" placeholder="Registry Number">
        </div>

        <div v-if="editUuid != null && formPayload.registry !== null ? formPayload.registry.is_need_credential : false">
          <hr>

          <div v-for="(credential, index) in formPayload.registry.credential_columns" :key="index" class="form-group">
            <label :for="credential.key">{{ formPayload.registry.name }} {{ credential.label }}:</label>
            <input type="text" :id="credential.key" class="form-control" v-model="formPayload.credentials[credential.key]" :placeholder="credential.label">
          </div>

        </div>

      </div>

      <template #modal-footer="{}">
        <!-- <b-button v-if="editUuid == null" variant="success" @click="createItem()">
          Save Topup
        </b-button> -->
        <b-button
          variant="success"
          @click="updateItem()"
        >
          Save Topup
        </b-button>
      </template>
    </b-modal>

  </b-card-code>
</template>

<script>
import _ from 'lodash'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title() {
    return `User Registry Number`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    vSelect,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      isLoading: false,
      filter: {
        status: this.$route.query.status == null ? '' : this.$route.query.status,
        user_id: '',
      },
      users: [],
      editUuid: null,
      formPayload: {
        registry_number: '',
        credentials: {},
      },
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 100),
      deep: true,
    },
  },
  created() {
    this.getData()
    this.executeSearch('')
  },
  methods: {
    editItem(item) {
      this.editUuid = item.uuid
      this.formPayload = {
        registry_number: item.registry_number,
        credentials: item.credentials,
        registry: item.registry,
      }

      // Initiate data to prevent this error (evaluating '_vm.formPayload.credentials[credential.key]')
      if (this.formPayload.credentials == null) {
        this.formPayload.credentials = {}
      }

      this.$bvModal.show('form-modal')
    },
    updateItem() {
      this.$http.patch('/admin/user-registry-number/' + this.editUuid, this.formPayload)
          .then(response => {

            this.$bvModal.hide('form-modal')
            this.getData(this.currentPage)
            successNotification(this, 'Success', 'Registry successfully updated!')

            this.cleanupForm();

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })
    },
    executeSearch(query) {
      const vm = this
      this.$http.get(`/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=${escape(query)}`)
        .then(response => {
          vm.users = []
          response.data.data.data.forEach(user => {
            vm.users.push({
              id: user.id,
              label: `${user.code} (${user.name})`,
            })
          })
        })
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUser(loading, search, this)
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(escape(search))
      loading(false)
    }, 300),
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/user-registry-number', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    approve(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'User will be able to withdraw Asset balance to this Registry Number.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`admin/user-registry-number/${uuid}/approve`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Registry Number has been approved',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    reject(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'User will not able to withdraw Asset balance to this Registry Number.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`admin/user-registry-number/${uuid}/reject`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Registry Number has been rejected',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
